import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logo-abb.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/abb-welcome-ip-simulator/abb-welcome-ip-simu.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const BuschJaegerSimulationTuerkommunikation = function (props, ref) {

    const data = useStaticQuery(graphql`
        query BuschJaegerSimulationTuerkommunikationQuery {
            heropic: file(relativePath: { eq: "images/projekte/abb-welcome-ip-simulator/landing/welcome-ip-og.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/abb-welcome-ip-simulator/landing/welcome-ip-og.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/abb-welcome-ip-simulator/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Webanwendung", "https://welcomeip.buildingproductsdemo.abb.com/"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "react"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="Welcome IP Simulator"
                description="Q:marketing realisiert virtuelle Produktsimulation zur Welcome IP Türkommunikation für ABB und Busch-Jaeger."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>Welcome IP Simulator</h1>                                                
                        <p className="introtext">Türkommunikationslösungen digital erleben. Mit dem Welcome IP Simulator machen wir moderne Türkommunikationslösung von ABB interaktiv erlebbar.</p>
                        <AnchorLink to="/projekte/virtuelle-produktpraesentationen/welcome-ip-simulator/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />                        
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                              
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Der Endkunde kann das Zusammenspiel zwischen einer Videoaußenstation und einer Innenstation digital ausprobieren.",
                        "Ausführliche Simulation der Funktionen.",
                        "Kostensparende Demonstration der Produkte.",
                    ]}
                />
            </ListenModule>
            

            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="juergen-dworak"/>

        </Layout>
    )
}

export default BuschJaegerSimulationTuerkommunikation